import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Page from '../components/Page'
import SEO from '../components/SEO'
import Schema from '../components/Schema'
import PageIntro from '../components/PageIntro'
import Newsletter from '../components/Newsletter'

const AboutPage = () => {
  const { markdownRemark, letterImage, neccLogo, fwdLogo, swaLogo } = useStaticQuery(graphql`
    query AboutPageQuery {
      markdownRemark(frontmatter: { path: { eq: "/about" } }) {
        frontmatter {
          title
          description
          pageIntro {
            ...PageIntroFragment
          }
          ourMission {
            title
            text
            image
          }
          story {
            header
            blurbs {
              title
              text
              image
            }
          }
        }
      }
      letterImage: file(relativePath: { eq: "about/jon-shayler-coo.jpg" }) {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      neccLogo: file(relativePath: { eq: "about/necc-logo.jpg" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      fwdLogo: file(relativePath: { eq: "about/fwd-logo.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      swaLogo: file(relativePath: { eq: "about/swa-logo.jpg" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter

  return (
    <Page className="p-about has-fingerPrints">
      <SEO title={pageData.title} description={pageData.description} />
      <Schema
        breadcrumbs={[
          {
            name: 'About',
            uri: '/about',
          },
        ]}
      />

      <PageIntro
        title={pageData.pageIntro.title}
        text={pageData.pageIntro.text}
        image={pageData.pageIntro.image}
      />

      <section className="c-section">
        <div className="c-section__outer container">
          <div className="c-section__inner c-section__inner--rp">
            <div className="c-sectionTitle p-0">
              <div className="row">
                <div className="col-12 col-md-7">
                  <h3 className="c-sectionTitle__header mb-5">
                    {pageData.ourMission.title}
                  </h3>
                  <p className="c-sectionTitle__text mb-5">
                    {pageData.ourMission.text}
                  </p>
                </div>
                <div className="col-12 col-md-5 mt-3 mb-2 text-center">
                  <img
                    className="img-fluid"
                    width="261"
                    src={`/images/icons/${pageData.ourMission.image}.png`}
                    srcSet={`/images/icons/${pageData.ourMission.image}.svg`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="our-story" className="c-section c-section--ourStory">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-ourStory">
              <div className="row">
                <div className="col-12 col-md-7 offset-md-3">
                  <h3 className="c-ourStory__header">
                    {pageData.story.header}
                  </h3>
                </div>
              </div>
              {pageData.story.blurbs.map((blurb) => (
                <div key={blurb.title} className="c-ourStoryBlerb row">
                  <div className="c-ourStoryBlerb__icon col-12 col-md-3">
                    <img
                      className="img-fluid"
                      width="150"
                      src={`/images/icons/${blurb.image}.png`}
                      srcSet={`/images/icons/${blurb.image}.svg`}
                    />
                  </div>

                  <div className="col-12 col-md-7">
                    <h2 className="c-ourStoryBlerb__header">{blurb.title}</h2>
                    <p
                      className="c-ourStoryBlerb__text"
                      dangerouslySetInnerHTML={{ __html: blurb.text }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section id="our-story" className="c-section c-section--cooNote">
        <div className="c-section__outer container">
          <div className="c-section__tag">
            A message from our{' '}
            <span className="d-none d-sm-inline">Chief Operating Officer</span>
            <span className="d-sm-none">COO</span>
          </div>
          <div className="c-section__inner">
            <div className="c-cooNote">
              <div className="row">
                <div className="col-12">
                  <h1 id="welcome-to-the-erudus-website">
                    Welcome to the Erudus website.
                  </h1>
                  <hr />
                  <p>
                    If there’s one thing you need to know about Erudus, it’s
                    that we’re united around food data. We want to make accurate
                    allergen, nutrition and technical food data easily
                    accessible to <strong>everyone</strong>, via our range of
                    software solutions.
                  </p>
                  <p>
                    Since 2007 Erudus has been taking product specifications
                    directly from Manufacturers and sharing them in one
                    centralised Data Pool with Wholesalers and Caterers.
                  </p>
                  <p>
                    In 2014, 2 pieces of legislation around food packaging and
                    labelling came into effect – FIC (Food Information to
                    Consumers) FIR (Food Information Regulations), which put
                    clear perimeters on how allergen information is made
                    available and presented. The introduction of this huge
                    allergy reform allowed Erudus to really come into our own –
                    by making it easier than ever for Manufacturers to share the
                    up-to-date allergen information for all their products with
                    their customers.
                  </p>
                  <p>
                    Erudus remain committed to being part of the allergen
                    movement and are proud that our work doesn’t just save our
                    customers time and money, but can also have a positive
                    impact on public safety.
                  </p>
                  <p>
                    And we’re about more than just allergies – our dedicated
                    Development team are constantly working on new tools and
                    apps to make every aspect of our customers’ lives easier,
                    from API integrations to Recipe Builders that will allow
                    Caterers to work out per serving costs for their favourite
                    menu items. Our data helps Wholesalers plan effective
                    delivery routes, and we make it easy to track waste
                    packaging – something increasingly important in today’s
                    environmentally aware world.
                  </p>
                  <p>
                    Most importantly, we listen. As a customer-centric
                    organisation, we welcome input from our users, and our road
                    map is planned with this feedback in mind. It’s our goal to
                    reduce the barriers to entry for our Data Pool and make food
                    data accessible to everyone in the industry, no matter what
                    the company size.
                  </p>
                  <p>
                    Via our news channel we keep our users up-to-date with
                    industry news that might affect them as well as tips and
                    advice on how to make the most of our database, and in our
                    Editorial section you can find inspirational recipes, fun
                    food stories, and learn more about the companies and people
                    in the Erudus community.
                  </p>
                  <p>
                    And we’re always here to talk if you have a question,
                    suggestion, or feedback - so feel free to get in touch.
                  </p>
                  <Img
                    className="img-fluid"
                    fixed={letterImage.childImageSharp.fixed}
                    title="Jon Shayler, COO of Erudus"
                    alt="Picture of the Erudus COO, Jon Shayler"
                  />
                  <p>
                    <strong>Jon Shayler</strong>
                    <br />
                    Chief Executive Officer
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-section c-section--pageIntro">
        <div className="c-section__outer container">
          <div className="c-section__inner pt-0">
            <div className="c-pageIntro text-left">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <p className="c-aboutAccr">
                    Erudus are proud to be accredited members of the North East
                    Chamber of Commerce.
                  </p>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="row">
                    <div className="col-12 col-sm-4 text-center">
                      <a
                        href="https://www.neechamber.co.uk/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Img
                          className="img-fluid"
                          fixed={neccLogo.childImageSharp.fixed}
                          title="Proud member of the North East Chamber of Commerce"
                          alt="North East Chamber of Commerce logo"
                        />
                      </a>
                    </div>
                    <div className="col-12 col-sm-4 text-center">
                      <a
                        href="https://www.fwd.co.uk/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Img
                          className="img-fluid"
                          fixed={fwdLogo.childImageSharp.fixed}
                          title="Federation of Wholesale Distributors"
                          alt="Federation of Wholesale Distributors logo"
                        />
                      </a>
                    </div>
                    <div className="col-12 col-sm-4 text-center">
                      <a
                        href="https://www.scottishwholesale.co.uk/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Img
                          className="img-fluid"
                          fixed={swaLogo.childImageSharp.fixed}
                          title="Scottish Wholesale Association"
                          alt="Scottish Wholesale Association logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export default AboutPage
